$container-width-70: 70%;
$container-width-80: 80%;
$container-width-90: 90%;

$base-color: #e20a17;
$base-color-hover: #ff2330;
$base-color-active: #c20611;

*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
  font: inherit;
}
body {
  min-height: 100vh;
  font-family: 'Montserrat', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, 'Segoe UI',
    -apple-system, BlinkMacSystemFont;
  background-color: #f5f5f5;
  font-size: 16px;
  font-weight: 400;
  color: #000;
}
img,
picture,
svg,
video {
  display: block;
  max-width: 100%;
}
input,
textarea,
button,
select {
  font: inherit;
}
p {
  line-height: 170%;
  .red {
    color: #e01022;
  }
}
h1,
h2,
h3,
hr,
h5,
h6,
strong {
  line-height: 1.7;
}
h1 {
  font-weight: 700;
  font-size: 2rem;
}
h2 {
  font-size: 1.4rem;
  font-weight: 600;
}
h3 {
  font-size: 1.4rem;
  font-weight: 400;
}
h4 {
  font-size: 1.4rem;
}
h5 {
  font-size: 1.1rem;
  font-weight: 400;
}
@media (max-width: 1400px) {
  h1 {
    font-size: 1.8rem;
  }
  h2,
  h3,
  h4 {
    font-size: 1.3rem;
  }
  h5 {
    font-size: 1rem;
  }
}
@media (max-width: 992px) {
  h1 {
    font-size: 1.7rem;
  }
  h2,
  h3,
  h4 {
    font-size: 1.2rem;
  }
}

strong {
  font-weight: 700;
}
a {
  text-decoration: none;
  color: #e01022;
  &:hover {
    color: #{$base-color-hover};
    text-decoration: underline;
  }
  &:active {
    color: #{$base-color-active};
    text-decoration: underline;
  }
  &.link-button {
    text-align: center;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #{$base-color};
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    padding: 12px 20px;
    margin: 10px 0;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.02),
      0px 4px 8px rgba(0, 0, 0, 0.04);
    transition: all 0.4s ease-in-out;
    cursor: pointer;
    &:hover {
      background-color: #{$base-color-hover};
      filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.1))
        drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.04));
    }
    &:active {
      background-color: #{$base-color-active};
    }
    &.white {
      background-color: #fff;
      color: #000;
      &:active {
        background-color: #fafafa;
      }
    }
  }
}

.container {
  width: #{$container-width-70};
  margin: 0 auto;
  @media (max-width: 1200px) {
    width: #{$container-width-80};
  }
  @media (max-width: 992px) {
    width: #{$container-width-90};
  }
}

/* scroll */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: rgb(206, 206, 206);
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
  &:hover {
    background: #555;
  }
}
