@import '../../App.scss';

.button {
  text-align: center;
  background-color: #fff;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 12px 20px;
  margin: 10px 0;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.02), 0px 4px 8px rgba(0, 0, 0, 0.04);
  filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.02))
    drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.04));
  cursor: pointer;
  &:hover {
    filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.1))
      drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.04));
  }
  &:active {
    background-color: #fafafa;
  }
  &.red {
    background-color: #{$base-color};
    color: #fff;
    &:hover {
      background-color: #{$base-color-hover};
    }
    &:active {
      background-color: #{$base-color-active};
    }
  }
  &.transparent {
    background-color: transparent;
    border: none;
    box-shadow: none;
    &:hover {
      color: #{$base-color};
    }
  }
  &:disabled {
    color: grey;
    opacity: 0.7;
    cursor: default;
  }
}
