@import '../../App.scss';

#nav {
  position: relative;
  width: 100%;
  z-index: 1001;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    padding-block: 2rem;

    .logo-container {
      min-width: 120px;
    }

    a {
      color: #fff;
    }

    .links {
      display: grid;
      gap: 1rem;

      ul {
        list-style-type: none;
        display: flex;

        li {
          margin-left: 1.5rem;
        }

        &:first-child {
          justify-content: end;
          opacity: 0.6;

          li {
            display: grid;
            grid-template-columns: 1fr auto;
            align-items: center;
            gap: 0.4rem;

            svg {
              width: 1.3rem;
            }
          }
        }

        &:nth-child(2) {
          a {
            font-weight: 600;
            text-decoration: none !important;

            &:hover {
              color: #{$base-color};
            }
          }

          li {
            display: flex;
            align-items: center;
            border-spacing: 14px !important;

            a {
              padding-block: 4px;
              border-bottom: 4px solid transparent;
            }

            a.active {
              border-bottom-color: #{$base-color};
            }
          }
        }
      }
    }
  }

  .hamburger-menu {
    display: none;
    height: 30px;
    width: 40px;
    background-color: transparent;
    border: none;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5%;
    cursor: pointer;

    span.line {
      position: absolute;
      display: none;
      height: 0;
      width: 100%;
      border-radius: 10px;
      border: 2.5px solid #222229;
      background-color: #222229;

      &.line1 {
        top: 0;
        transform-origin: 0 0;
        transition: transform 0.4s ease-in-out;
      }

      &.line2 {
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.2s ease-in-out;
      }

      &.line3 {
        bottom: 0;
        transform-origin: 0 100%;
        transition: transform 0.4s ease-in-out;
      }
    }
  }

  .language-selector {
    position: absolute;
    right: 15%;
    top: .5rem;
  }

  @media (max-width: 1200px) {
    .language-selector {
      right: 10%;
    }
    .menu-items {
      text-align: center;
    }
  }
  @media (max-width: 992px) {
    position: fixed;
    top: 0;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05),
    0 20px 30px rgba(0, 0, 0, 0.04);
    .container {
      color: #000;
      padding-block: 1rem;

      .logo-container {
        z-index: 5;

        svg {
          path.text {
            fill: #000;
          }
        }
      }

      a {
        color: #000;
      }

      .links {
        ul:first-child {
          display: none;
        }

        .menu-items {
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          width: 100%;
          display: grid;
          justify-items: center;
          align-items: center;
          gap: 1rem;
          background-color: #fff;
          padding-block: 2rem;
          border: 1px solid rgba(0, 0, 0, 0.1);
          box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05),
          0 20px 30px rgba(0, 0, 0, 0.04);
          transform: translateY(-150%);
          transition: transform 0.6s ease-in-out;

          &.active {
            transform: translateY(0);
          }
        }
      }
    }
    .hamburger-menu {
      display: flex;
      z-index: 5;

      span.line {
        display: block;
      }

      &.active {
        .line1 {
          transform: rotate(45deg);
        }

        .line2 {
          opacity: 0;
        }

        .line3 {
          transform: rotate(-45deg);
        }
      }
    }
    .language-selector {
      position: relative;
      right: unset;
      top: unset;
    }
  }
  @media (max-width: 350px) {
    .logo-container {
      width: 50%;
    }
  }
}
