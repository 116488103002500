@import '../../App.scss';

.header {
  background-image: url('../../images/header-bg.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  position: relative;
  padding-bottom: 5rem;
  background-color: #222229;
  height: 500px;

  .container:nth-child(2) {
    position: relative;
    z-index: 1;
    margin-top: 2rem;
    height: 100%;

    h1 {
      margin-bottom: 1rem;
    }

    h1 + p {
      font-size: 1.9rem;
      width: 50%;
      font-weight: 400;
    }

    img {
      position: absolute;
      right: -2rem;
      bottom: 0;
      z-index: 10;
    }
  }

  @media (max-width: 1400px) {
    height: 550px;
  }
  @media (max-width: 1200px) {
    .container:nth-child(2) {
      padding-top: 5rem;
      text-align: center;

      h1 + p {
        width: 100%;
      }

      & > img {
        display: none;
      }
    }
  }
  @media (max-width: 992px) {
    .container:nth-child(2) {
      padding-top: 0;

      h1 {
        font-size: 1.7rem;
      }

      h1 + p {
        font-size: 1.5rem;
      }
    }
    padding-top: 10rem;
  }
}

.home-header {
  background-color: #222229;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;
  min-height: 800px;
  max-height: 1000px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  animation: moveHeader 15s infinite ease-in-out 1s;

  &.with-bg-img {
    background-image: url('../../images/home-header-bg.png');
  }

  @media (max-width: 1200px) {
    animation-duration: 30s;
  }
  @media (max-width: 992px) {
    animation-duration: 40s;
  }
  @media (max-width: 768px) {
    animation-duration: 50s;
  }
  @media (max-width: 475px) {
    animation-duration: 60s;
  }

  .container:nth-child(2) {
    width: 60%;
    position: absolute;
    bottom: 10rem;
    left: calc((100% - #{$container-width-70}) / 2);
    display: grid;
    gap: 3rem;

    h1 {
      width: 80%;
    }

    h1 + p {
      font-size: 1.9rem;
      width: 80%;
      font-weight: 400;
    }

    .actions {
      width: max-content;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      gap: 1rem;
    }

    @media (max-width: 1400px) {
      bottom: 8rem;
      width: 80%;
    }
    @media (max-width: 1200px) {
      left: calc((100% - #{$container-width-80}) / 2);
    }
    @media (max-width: 992px) {
      text-align: center;
      left: calc((100% - #{$container-width-90}) / 2);
      bottom: 3rem;
      width: 90%;
      h1,
      h1 + p {
        width: 100%;
      }
      .actions {
        grid-template-columns: 1fr;
        justify-items: center;
        margin: 0 auto;
      }
    }
    @media (max-width: 475px) {
      h1 {
        font-size: 1.9rem;
      }
      h1 + p {
        font-size: 1.6rem;
      }
    }
  }

  & > span {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: 992px) {
    min-height: 700px;
    & > span {
      display: none;
    }
  }
}

@keyframes moveHeader {
  0% {
    background-position: left;
  }
  50% {
    background-position: right;
  }
  100% {
    background-position: left;
  }
}
