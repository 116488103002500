@import '../../App.scss';

#home {
  svg:not(h2 + svg) {
    transition: transform 0.3s ease-in-out;
    &:hover {
      transform: rotate(5deg);
    }
  }
  section:first-child .container {
    display: grid;
    align-items: center;
    justify-items: center;
    gap: 1.5rem;
    img {
      margin-block: 2rem;
    }
    p {
      width: 60%;
      text-align: center;
    }
    .actions {
      display: grid;
      grid-template-columns: repeat(3, auto);
      gap: 1rem;
      justify-items: center;
    }
    @media (max-width: 768px) {
      p {
        width: 100%;
      }
      .actions {
        grid-template-columns: 1fr;
      }
    }
  }
  section:nth-child(2) {
    color: #fff;
    background-image: url('../../images/home/section-bg.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top left;
    .container {
      display: grid;
      gap: 1rem;
      & > div {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 3rem;
        .text-container {
          width: 80%;
          display: grid;
          gap: 1rem;
        }
        .icons-container {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 1.5rem;
          align-items: flex-start;
          & > div {
            display: grid;
            grid-template-columns: 1fr;
            gap: 1rem;
            align-items: flex-start;
            text-align: center;
            span {
              width: 40px;
              height: 40px;
              justify-self: center;
              svg {
                min-width: 2rem;
              }
            }
          }
        }
      }
      @media (max-width: 1400px) {
        & > div {
          grid-template-columns: 1fr;
          .text-container {
            width: 100%;
          }
        }
      }
      @media (max-width: 768px) {
        justify-items: center;
        & > div {
          grid-template-columns: 1fr;
          row-gap: 5rem;
          .icons-container {
            grid-template-columns: repeat(2, 1fr);
          }
        }
        .text-container {
          justify-items: center;
          margin: 0 auto;
          width: 90%;
          text-align: center;
        }
      }
      @media (max-width: 350px) {
        & > div {
          .icons-container {
            grid-template-columns: 1fr;
          }
        }
      }
    }
  }
  section:nth-child(3) {
    .container {
      .text-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 5rem;
        padding-bottom: 4rem;
        & > div {
          display: flex;
          flex-direction: column;
          row-gap: 1rem;
        }
      }
      .icons-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: flex-start;
        gap: 5rem;
        & > div {
          display: grid;
          justify-items: center;
          gap: 1rem;
          svg {
            width: 60px;
            height: 60px;
            color: #{$base-color};
          }
        }
      }
      .actions {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        margin-block: 2rem;
        a {
          width: 100%;
        }
      }
      @media (max-width: 768px) {
        .text-container {
          grid-template-columns: 1fr;
          row-gap: 5rem;
          & > div {
            align-items: center;
            text-align: center;
          }
        }
        .icons-container {
          grid-template-columns: 1fr;
          & > div {
            align-items: center;
            h5 {
              color: #{$base-color};
              font-weight: 700;
            }
            p {
              text-align: center;
            }
          }
        }
        .actions {
          grid-template-columns: 1fr;
          justify-items: center;
          margin-inline: auto;
        }
        .actions + div {
          text-align: center;
        }
      }
    }
  }
  section:nth-child(4) {
    .container {
      display: grid;
      grid-template-columns: 3fr 1fr;
      justify-content: space-between;
      column-gap: 3rem;
      .text-container {
        display: grid;
        gap: 1rem;
        & > div {
          display: grid;
          gap: 1rem;
        }
      }
      .image-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      a:last-child {
        margin-block: 3rem;
      }
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
        row-gap: 3rem;
        .text-container {
          justify-items: center;
          text-align: center;
        }
        .image-container + div {
          a {
            margin: 0 auto;
          }
        }
      }
    }
  }
  section:nth-child(5) {
    .container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 3rem;
      .text-container {
        display: grid;
        gap: 1rem;
      }
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
        row-gap: 3rem;
        .text-container {
          justify-items: center;
        }
      }
    }
  }
}
