.language-selector {
  display: flex;
  align-items: center;
  height: 1.5rem;
  width: 5rem;
  column-gap: .5rem;

  span {
    cursor: pointer;
    border: 1px solid #000;
    height: 100%;
    width: 100%;
    opacity: .6;
    filter: grayscale(1);

    &.active, &:hover {
      opacity: 1;
      filter: grayscale(0);
    }

    img {
      height: 100%;
      width: 100%;
    }
  }
}
