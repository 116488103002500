#root {
  display: grid;
  grid-template-areas:
    'header'
    'main'
    'footer';
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  header {
    grid-area: header;
    color: #fff;
  }
  main {
    $bottomSpace: 12rem;
    grid-area: main;
    & > div:not(#home) {
      padding-top: 6rem;
    }
    position: relative;
    background-color: #f5f5f5;
    padding-bottom: #{$bottomSpace};
    #contact {
      margin-bottom: -#{$bottomSpace};
    }
    section {
      padding-block: 5rem;
      &.white {
        background-color: #fff;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 15.9009px 23.8514px rgba(0, 0, 0, 0.02),
          0px 3.97523px 7.95046px rgba(0, 0, 0, 0.04);
      }
      @media (max-width: 992px) {
        padding-block: 2rem;
      }
    }
    @media (max-width: 992px) {
      padding-top: 2rem;
    }
    @media (max-width: 1200px) {
      & > div:not(#home) {
        padding-top: 0;
      }
    }
  }
  footer {
    grid-area: footer;
  }
}

.scroll-to-top {
  position: fixed;
  bottom: 10%;
  right: 5%;
  width: 3rem;
  height: 3rem;
  z-index: 2000;
  &::before {
    content: '';
    display: inline-block;
    transform: rotate(-135deg);
    border: solid #222229;
    border-width: 0 3px 3px 0;
    padding: 3px;
  }
}
